import { routeNames } from '../../router/constants';

export const SAVE_FILE_OBJECT = 'SAVE_FILE_OBJECT';
export const SAVE_FILE_CONSENT = 'SAVE_FILE_CONSENT';
export const SAVE_FILE_PRESCRIPTION = 'SAVE_FILE_PRESCRIPTION';
export const OPEN_CAMERA = 'OPEN_CAMERA';

export const BROWSE_FOR_DNR_CONSENT = 'BROWSE_FOR_DNR_CONSENT';
export const BROWSE_FOR_DNR_PRESCRIPTION = 'BROWSE_FOR_DNR_PRESCRIPTION';
export const DEFAULT_MENU = {
  main: 'home',
  menu: [
    {
      id: '1',
      'short-name': 'Home',
      module_name: 'dashboard',
      url: 'Dashboard',
      icon: 'HOME',
      title: 'Home',
      premium: 0,
      displayIcon: false
    },
    {
      id: '2',
      'short-name': 'K-hub',
      module_name: 'KHUB',
      url: 'contents',
      icon: 'KHUB',
      title: 'Knowledge Hub',
      premium: 0,
      displayIcon: false,
      popDescription: ' Stay updated with the latest research and medical updates'
    },
    {
      id: '3',
      'short-name': 'CPD',
      module_name: 'community',
      url: 'sessions',
      icon: 'CPD',
      title: 'CPD',
      premium: 0,
      displayIcon: false,
      popDescription: 'Access expert led webinars'
    },

    {
      id: '6',
      'short-name': 'Product',
      module_name: 'ddx',
      url: 'https://www.alkemlabs.com/our-offerings/rx-products',
      icon: 'PRODUCT',
      title: 'Alkem Rx Products',
      external: true,
      premium: 0,
      displayIcon: false
    },
    {
      id: '7',
      'short-name': 'Profile',
      url: 'profile',
      icon: 'PROFILE',
      title: 'My Profile',
      module_name: 'Profile',
      premium: 0,
      displayIcon: false
    },
    {
      id: '8',
      'short-name': 'Logout',
      url: 'Logout',
      icon: 'LOGOUT',
      title: 'Logout',
      module_name: 'Logout',
      premium: 0,
      displayIcon: false,
      logout: true
    }
    // {
    //   id: '4',
    //   'short-name': 'Explore',
    //   url: null,
    //   icon: '<i class="flaticon-other"></i>',
    //   title: 'Explore',
    //   premium: false,
    //   displayIcon: false,
    //   module_name: 'explore_more',
    //   sub_menu: [

    //   ]
    // }
  ],
  all_menu: [
    {
      id: '1',
      'short-name': 'Home',
      url: 'Dashboard',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Home-icon.svg',
      title: 'Home',
      visible: true
    },
    {
      id: '2',
      'short-name': 'Live CME',
      url: 'Sessions',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/LiveCME-icon.svg',
      title: 'Live CME',
      visible: true
    },
    {
      id: '3',
      'short-name': 'MedWiki&reg;',
      url: 'Medwiki',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Medwiki-icon.svg',
      title: 'MedWiki&reg;',
      visible: true
    },
    {
      id: '4',
      'short-name': 'DocTube &trade;',
      url: 'Doctube',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Doctube-icon.svg',
      title: 'Doctube &trade;',
      visible: true
    },
    {
      id: '5',
      'short-name': 'Video',
      url: 'ClinicalVideoLanding',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/ClinicalVideo-icon.svg',
      title: 'Clinical Videos',
      visible: true
    },
    {
      id: '6',
      'short-name': 'Community',
      url: 'Channel',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Channel-icon.svg',
      title: 'Community',
      visible: true
    },
    {
      id: '7',
      'short-name': 'GR',
      url: 'GrandRound',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/GrandRound-icon.svg',
      title: 'Grand Rounds',
      visible: true
    },
    {
      id: '8',
      'short-name': 'Course',
      url: 'TrainingLanding',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/training-icon.svg',
      title: 'Certified Courses',
      visible: true
    },
    {
      id: '9',
      'short-name': 'SPQ',
      url: 'SPQlanding',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Polls-icon.svg',
      title: 'Poll & Quizzes',
      visible: true
    },
    {
      id: '10',
      'short-name': 'DDX',
      url: 'DifferentialDiagnosis',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/aida.svg',
      title: 'Differential Diagnosis',
      visible: true
    },
    {
      id: '11',
      'short-name': 'E-Paper',
      url: 'Ebook',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/EBook-icon.svg',
      title: 'E-Paper',
      visible: true
    },
    {
      id: '12',
      'short-name': 'TeleMed',
      url: 'Telemed',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Telemed-icon.svg',
      title: 'Telemed Lite',
      visible: true
    },
    {
      id: '13',
      'short-name': 'D&R',
      url: 'DiscussRefer',
      icon: 'https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/DiscussAndRefer-icon.svg',
      title: 'Discuss & Refer',
      visible: true
    }
  ],
  static_menu: [
    {
      id: '1',
      'short-name': 'Home',
      url: 'Dashboard',
      icon: '\u003Ci class="flaticon-home-speciality"\u003E\u003C/i\u003E',
      title: 'Home',
      visible: true
    },
    {
      id: '4',
      'short-name': 'DocTube &trade;',
      url: 'Doctube',
      icon: '\u003Ci class="flaticon-doctube"\u003E\u003C/i\u003E',
      title: 'Doctube &trade;',
      visible: true
    },
    {
      id: '6',
      'short-name': 'Community',
      url: 'Channel',
      icon: '\u003Ci class="flaticon-icon-material-group"\u003E\u003C/i\u003E',
      title: 'Community',
      visible: true
    }
  ]
};
