import React, { memo, useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { Pagination, Navigation, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import './css/desktopHeader.scss';
SwiperCore.use([Pagination, Navigation, Mousewheel]);

import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import {
  AiOutlineLogout,
  AiOutlineQuestionCircle,
  GetSupport,
  NewSupport,
  BiMapPin,
  BsBell,
  Cpd,
  HiOutlineBookmark,
  HomeNew,
  Khub,
  LogoutNew,
  Products,
  ProfileNew,
  Resourses
} from '../../components/icons';
import { ClirnetFullLogo, ClirnetIconLogo } from '../../components/icons/menuIconComponents';
import packageInfo from '../../../package.json';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { renderHeaderOnce, resetStore, toggleGlobalModal } from '../../Store/actions';
import { logoutUser } from '../../Store/actions/login.action';
import {
  DEMO_USER_PICTURE,
  changeUrl,
  convertAllMenu,
  deleteCaches,
  getLocalStorage,
  reactHtmlParser,
  setSessionStorage
} from '../../common/common';
import JoyRide from '../reactJoyRide/JoyRide';

import { openModalStatus } from '../../Store/actions';

import { fetchUnreadCount, loginToCrm } from '../../Store/actions/notification.action';
import { DEFAULT_MENU } from '../../Store/constants/app.constants';
import LazyImage from '../CustomLibraries/LazyImage';
import DeleteConfirmation from '../modals/DeleteConfirmation';
import HeaderSearchBar from './HeaderSearchBar';
import { routeNames } from '../../router/constants';
import LoaderLine from '../LoaderLine';
import useRedirect from '../customHooks/useRedirect';
import { openInNewTab } from '../banner/utils/openInNewTab';
import { getIcon } from 'react-toastify/dist/components';
import RedirectModal from '../modals/RedirectModal';
import TooltipContainer from '../cards/tooltip/TooltipContainer';
function HeaderDesktop({ autoauthName = '' }) {
  const current_menu = useSelector((state) => state.common.current_menu);
  const [showredirectModal, setShowredirectModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [currentMenu, setCurrentMenu] = useState('');
  const [isHover, setIsHover] = useState(false);
  const [vault, setVault] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const points = useSelector((state) => state.common.points);
  const point_api_called = useSelector((state) => state.common.point_api_called);

  const renderHeader = useSelector((state) => state.common.renderHeader);
  const [showTourButton, setShowTourButton] = useState(false);
  let app_tour_data = useSelector((state) => state.appTour.app_tour_data);
  let app_tour_modal_status = useSelector((state) => state.appTour.app_tour_modal_status);
  const notifications = useSelector((state) => state.notification);
  const [{ deletionType, deletionModalShow }, setDeletionConfirmation] = useState({
    deletionType: '',
    deletionModalShow: false
  });
  const [autoauthNameHeader, setAutoauthName] = useState(false);
  const userInfo = useSelector((state) => state.login.userDetails);
  const userDetails = useSelector((state) => state.common.user_details);
  let [onclickTourView, setOnclickTourView] = useState(0);
  const [menuJson, setMenuJson] = useState(DEFAULT_MENU);

  const { redirectTo } = useRedirect();
  useEffect(() => {
    if (userInfo) {
      setUser(userInfo);
      setVault(getLocalStorage('vaultCount', 0));
    } else {
      if (getLocalStorage('user', false)) {
        setUser(getLocalStorage('user', {}));
      }
      if (getLocalStorage('vaultCount', 0)) {
        setVault(getLocalStorage('vaultCount', 0));
      }
    }
    // if (getLocalStorage('user', false) && JSON.parse(getLocalStorage('user', {})?.menu)) {
    //   const menu = JSON.parse(getLocalStorage('user', {})?.menu);
    //   if (menu) {
    //     // if ('menu' in menu) {
    //     //   setMenuJson({
    //     //     ...menu
    //     //   });
    //     // } else setMenuJson(DEFAULT_MENU);

    //     setMenuJson(DEFAULT_MENU);
    //   }
    // }
  }, [userInfo]);

  const getUnreadCount = () => {
    // dispatch(
    //   loginToCrm((res) => {
    //     const { access_token } = res;
    //     dispatch(fetchUnreadCount(access_token, (res) => {}));
    //   })
    // );
  };
  useEffect(() => {
    document.body.classList.toggle('menuShow', open);
  }, [open]);

  useEffect(() => {
    if (notifications?.count_called == false) {
      if (notifications?.accessToken) {
        dispatch(fetchUnreadCount(notifications?.accessToken, (res) => {}));
      } else {
        getUnreadCount();
      }
    }
  }, [notifications?.count_called]);

  const logout = (type) => {
    if (type == 'logout') {
      // navigate('/');
      window.location.href = '/';
      dispatch(logoutUser());
      dispatch(resetStore());
      deleteCaches();
    }
  };
  useEffect(() => {
    // console.log('APP_TOUR_DATA', app_tour_data);
    if (app_tour_data) {
      if (
        app_tour_data?.data &&
        app_tour_data?.data?.maxtoursession &&
        app_tour_data?.data?.maxtoursession != '0'
      ) {
        setShowTourButton(true);
      }
    } else {
      setShowTourButton(false);
    }
  }, [app_tour_data]);

  const onTakeTourClick = () => {
    dispatch(openModalStatus(true));
    setOnclickTourView(1);
    setSessionStorage('sessionTourVal', 1);
  };
  const onNotificationClick = () => {
    !location?.pathname?.toLowerCase()?.includes('notification') &&
      redirectTo(routeNames.getSupport.landing);
  };
  // console.log('session val',getSessionStorage('sessionViewedStatus',0));
  const addHoverClass = () => setIsHover(true);
  const removeHoverClass = () => setIsHover(false);

  // onScroll add class header

  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (current_menu?.toLowerCase()?.includes('dashboard')) {
      setCurrentMenu('dashboard');
    } else if (current_menu?.toLowerCase()?.includes('session')) {
      setCurrentMenu('session');
    } else if (current_menu?.toLowerCase()?.includes('spq')) {
      setCurrentMenu('spq');
    } else if (current_menu?.toLowerCase()?.includes('clinical')) {
      setCurrentMenu('clinical');
    } else if (current_menu?.toLowerCase()?.includes('gr')) {
      setCurrentMenu('grandround');
    } else if (
      current_menu?.toLowerCase()?.includes('ebook') ||
      current_menu?.toLowerCase()?.includes('epaper')
    ) {
      setCurrentMenu('ebook');
    } else if (current_menu?.toLowerCase()?.includes('channel')) {
      setCurrentMenu('channel');
    } else if (current_menu?.toLowerCase()?.includes('telemed')) {
      setCurrentMenu('telemed');
    } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
      setCurrentMenu('discussrefer');
    } else if (
      current_menu?.toLowerCase()?.includes('training') ||
      current_menu?.toLowerCase()?.includes('course')
    ) {
      setCurrentMenu('training');
    } else if (current_menu?.toLowerCase()?.includes('doctube')) {
      setCurrentMenu('doctube');
    } else if (current_menu?.toLowerCase()?.includes('speciality')) {
      setCurrentMenu('speciality');
    } else if (
      current_menu?.toLowerCase()?.includes('diagnosis') ||
      current_menu?.toLowerCase()?.includes('ddx')
    ) {
      setCurrentMenu('diagnosis');
    } else if (current_menu?.toLowerCase()?.includes('observership')) {
      setCurrentMenu('observership');
    } else {
      setCurrentMenu(current_menu?.toLowerCase());
    }
  }, [current_menu]);

  function getActiveClass(path) {
    if (currentMenu !== '') {
      if (
        currentMenu.includes(path?.toLowerCase()) ||
        path?.toLowerCase().includes(currentMenu?.toLowerCase())
      ) {
        return 'active';
      }
    }
  }

  function getNavbarIcon(iconName) {
    switch (iconName) {
      case 'HOME':
        return <HomeNew className="icon-size-24" />;
      case 'KHUB':
        return <Khub className="icon-size-24" />;
      case 'CPD':
        return <Cpd className="icon-size-24" />;
      case 'RESOURCES':
        return <Resourses className="icon-size-24" />;
      case 'PRODUCT':
        return <Products className="icon-size-24" />;
      case 'PROFILE':
        return <ProfileNew className="icon-size-24" />;
      case 'LOGOUT':
        return <LogoutNew className="icon-size-20" />;
      default:
        return '';
    }
  }

  const inDashboardPage = pathname == '/' || pathname.includes('dashboard') ? true : false;

  return (
    <>
      <Navbar expand="false" className="deskHeader p-0 bg-white fixed-top">
        <JoyRide />

        <div
          id="headerDesktopNavBar"
          className="w-100 deskHeaderTop position-relative shadow topHeader"
        >
          <div className="w-100 deskMainHeader bg-white px-20 d-flex justify-content-between align-items-center">
            <div className="deskTpNav_left d-flex justify-content-between align-items-center">
              <button
                type="button"
                className={`deskMenuToggle position-relative p-0 bg-transparent border-0 navbar-toggler ${
                  !open ? 'collapsed' : ''
                }`}
                onClick={() => {
                  setOpen(!open);
                }}
                aria-label="Nav Toggle"
              >
                <span className="navbar-toggler-icon bg-black position-absolute top-50 start-50"></span>
              </button>
            </div>
            <div className="deskTpNav_Right d-inline-flex align-items-center gap-5">
              <HeaderSearchBar currentMenu={currentMenu} />

              <Nav className="deskNavRight flex-shrink-0 flex-row align-items-center">
                <Nav.Link
                  onClick={onNotificationClick}
                  className="dskNotificationBtn d-flex align-items-center flex-shrink-0 gap-2 fw-medium text-black position-relative gtm_cl_header_notification align-ites-center justify-content-center"
                  id="header_desktop_notification_anchor_text"
                >
                  <span className="flex-shrink-0 ">Get Support</span>
                  <NewSupport
                    gtmTagVariable="gtm_cl_header_helpsupport"
                    customClass="icon-size-28"
                    id="header_desktop_helpSupport_icon"
                  />
                </Nav.Link>
                {showTourButton && (
                  <Nav.Link
                    onClick={onTakeTourClick}
                    className="dskTakeTourBtn shineEffect fw-medium text-black me-4 rounded-5 py-3 px-4 gtm_cl_header_takeTour"
                    id={'header_desktop_takeTour_anchor_text'}
                  >
                    <span id="header_desktop_takeTour_text" className="gtm_cl_header_takeTour">
                      <BiMapPin
                        gtmTagVariable="gtm_cl_header_takeTour"
                        customClass="fs-30 text-black header_desktop_takeTour_icon"
                        id={'header_desktop_takeTour_icon'}
                      />
                      Take Tour
                    </span>
                  </Nav.Link>
                )}
              </Nav>
              <h5 className="fs-4 ">
                Powered by <span className="text-secondary fw-semibold">ALKEM</span>
              </h5>
            </div>
          </div>
          {inDashboardPage && !scrolled && (
            <div className="w-100 bg-secondary d-flex align-items-center justify-content-center text-center text-white h-48px fs-4 fw-semibold ">
              For Registered Medical Practitioners Only
            </div>
          )}
        </div>
        <Navbar.Collapse
          className={`deskLeftmenuBar position-fixed start-0 bg-transparent d-block pt-70 ${
            open ? 'fullMenuShow' : ''
          }`}
        >
          {/* "fullMenuShow" className should be added when the collapsed menu open */}
          <Navbar.Brand
            className="position-absolute top-0 start-0 p-0 m-0 d-block w-100"
            href="javascript:void(0)"
            onClick={() => {
              if (getLocalStorage('main_menu', '') === 'dnr') {
                setCurrentMenu('discussRefer');
                redirectTo(routeNames.discussRefer.landing);
              } else {
                setCurrentMenu('dashboard');
                redirectTo(routeNames.dashboard.landing);
              }
            }}
          >
            <div
              className="topLogo position-absolute top-50 start-50 translate-middle bg-white rounded-circle gtm_cl_header_clirnet_logo"
              id="header_desktop_clirnet_logo_span"
            >
              <ClirnetIconLogo
                gtmTagVariable="gtm_cl_header_clirnet_logo"
                className={'topLogo1 position-absolute top-50 start-50 w-auto'}
                id="header_desktop_clirnet_icon_logo"
              />
              <ClirnetFullLogo
                gtmTagVariable="gtm_cl_header_clirnet_logo"
                className={'topLogo2 position-absolute top-50 start-50 w-100'}
                id="header_desktop_clirnet_full_logo"
              />
            </div>
          </Navbar.Brand>
          <Nav
            className="fs-4 fw-normal deskNavMenu h-100 bg-white d-block shadow"
            activeKey={pathname}
            defaultActiveKey={'/dashboard'}
            // style={{ width: '8rem' }}
          >
            <Swiper
              className={`deskNavMenuSlider cmnLndngRowSlide h-100 pt-3 pb-5 ${
                isHover ? 'deskMunuLinkHover' : ''
              }`}
              direction={'vertical'}
              // autoHeight={'true'}
              mousewheel={true}
              spaceBetween={8}
              slidesPerView={'auto'}
              loop={false}
              navigation={{ clickable: true }}
            >
              {menuJson &&
                menuJson.menu?.length > 0 &&
                menuJson?.menu?.map((key, ind) =>
                  'sub_menu' in key && key.sub_menu?.length > 0 ? (
                    key.sub_menu?.map((elem, ind) =>
                      elem?.logout && !('user_master_id' in userDetails) ? null : (
                        <SwiperSlide
                          key={ind + 1}
                          className="deskNavMenuSingle"
                          onMouseEnter={addHoverClass}
                          onMouseLeave={removeHoverClass}
                        >
                          <TooltipContainer text={elem?.popDescription || ''} placement="top">
                            <Nav.Link
                              className={`deskMunuLink d-flex align-items-center position-relative text-dark mb-2 ${getActiveClass(
                                elem?.url
                              )} gtm_cl_header_${elem.title}`}
                              href="javascript:void(0)"
                              onClick={() => {
                                if (elem?.external) {
                                  setShowredirectModal(true);
                                  setRedirectUrl(elem?.url);
                                } else if (elem?.logout) {
                                  logout('logout');
                                } else {
                                  setCurrentMenu(elem.title);
                                  redirectTo(changeUrl(elem.url));
                                }
                              }}
                              id={`header_desktop_${elem.title}_anchor_text`}
                              key={ind}
                            >
                              <span
                                className={`deskMunuLinkIcon h-32px z-1 position-absolute start-0 gtm_cl_header_${elem.title}`}
                                id={`header_desktop_${elem.title}_icon_span`}
                              >
                                <div className="deskTopmenuSVG icon-size-32 position-absolute top-50 start-50 translate-middle text-center">
                                  {getNavbarIcon(elem.icon)}
                                </div>
                              </span>
                              <span
                                className={`deskMunuLinkTxt2 z-1 position-relative text-nowrap ms-n3 gtm_cl_header_${elem.title}`}
                                id={`header_desktop_${elem.title}_text`}
                              >
                                {reactHtmlParser(elem.title)}
                              </span>

                              <span
                                className={`deskMunuLinkTxt z-1 position-absolute start-0 text-nowrap text-center gtm_cl_header_${elem.title}`}
                              >
                                {reactHtmlParser(elem['short-name'])}
                              </span>
                            </Nav.Link>
                          </TooltipContainer>
                        </SwiperSlide>
                      )
                    )
                  ) : key?.logout && userDetails == '' ? null : (
                    <SwiperSlide
                      key={ind + 1}
                      className="deskNavMenuSingle"
                      onMouseEnter={addHoverClass}
                      onMouseLeave={removeHoverClass}
                    >
                      <TooltipContainer text={key?.popDescription || ''} placement="top">
                        <Nav.Link
                          className={`deskMunuLink d-flex align-items-center position-relative text-dark mb-2 ${getActiveClass(
                            key?.url
                          )} gtm_cl_header_${key.title}`}
                          href="javascript:void(0)"
                          onClick={() => {
                            if (key?.external) {
                              setShowredirectModal(true);
                              setRedirectUrl(key?.url);
                            } else if (key?.logout) {
                              logout('logout');
                            } else if (key?.url == 'Dashboard') {
                              setCurrentMenu(key.title);
                              if (pathname != '/' && userDetails == '') {
                                dispatch(
                                  toggleGlobalModal({
                                    show: true,
                                    message:
                                      'Please login/register with us to continue using Medisamvad',
                                    icon: (
                                      <i
                                        className="flaticon-lock-symbol  opacity-50"
                                        style={{ fontSize: '3rem' }}
                                      />
                                    ),
                                    size: 'sm'
                                  })
                                );
                              }

                              navigate('/' + key?.url);
                            } else {
                              setCurrentMenu(key.title);
                              redirectTo(changeUrl(key.url));
                            }
                          }}
                          id={`header_desktop_${key.title}_anchor_text`}
                        >
                          <span
                            className={`deskMunuLinkIcon h-32px z-1 position-absolute start-0 gtm_cl_header_${key.title}`}
                            id={'header_desktop_' + key.title + '_icon_span'}
                          >
                            <div className="deskTopmenuSVG icon-size-24 position-absolute top-50 start-50 translate-middle text-center">
                              {getNavbarIcon(key.icon)}
                            </div>
                          </span>
                          <span
                            className={`deskMunuLinkTxt2 z-1 position-relative text-nowrap ms-n3 gtm_cl_header_${key.title}`}
                            id={`header_desktop_${key.title}_text`}
                          >
                            {reactHtmlParser(key.title)}
                          </span>

                          <span
                            className={`deskMunuLinkTxt z-1 position-absolute start-0 text-nowrap text-center gtm_cl_header_${key.title}`}
                          >
                            {reactHtmlParser(key['short-name'])}
                          </span>
                        </Nav.Link>
                      </TooltipContainer>
                    </SwiperSlide>
                  )
                )}
              {open && (
                <SwiperSlide className="h-auto px-4">
                  {/* <div className="d-flex flex-row gap-2 mt-5">
                    <a
                      href="javascript:void(0)"
                      className="icon-size-36 btn btn-light p-0 d-flex justify-content-center align-items-center rounded-2"
                    >
                      <i className="flaticon-facebook-app-symbol fs-4"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="icon-size-36 btn btn-light p-0 d-flex justify-content-center align-items-center rounded-2"
                    >
                      <i className="flaticon-twitter fs-4"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="icon-size-36 btn btn-light p-0 d-flex justify-content-center align-items-center rounded-2"
                    >
                      <i className="flaticon-instagram fs-4"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="icon-size-36 btn btn-light p-0 d-flex justify-content-center align-items-center rounded-2"
                    >
                      <i className="flaticon-linkedin fs-4"></i>
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="icon-size-36 btn btn-light p-0 d-flex justify-content-center align-items-center rounded-2"
                    >
                      <i className="flaticon-youtube fs-4"></i>
                    </a>
                  </div> */}
                  <h4 className="fs-4 text-black fw-medium mt-20">Alkem v{packageInfo?.version}</h4>
                </SwiperSlide>
              )}
            </Swiper>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {deletionType && (
        <DeleteConfirmation
          title={`Are you sure to log out ?`}
          type={deletionType}
          confirm={deletionModalShow}
          handleClose={() =>
            setDeletionConfirmation({ deletionType: '', deletionModalShow: false })
          }
          confirmDeletion={logout}
        />
      )}
      <RedirectModal
        show={showredirectModal}
        url={redirectUrl}
        onHide={() => {
          setShowredirectModal(false);
        }}
      />
    </>
  );
}

export default memo(HeaderDesktop);
