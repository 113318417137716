import React from 'react';
import Loadable from '../Loadable';
import DefaultLayout from '../../layout/DefaultLayout';

const Login = Loadable({
  loader: () => import('../../../src/pages/login/LoginNew')
});

// const Registration = Loadable({
//   loader: () => import('../../pages/registration/Registration')
// });

const Dashboard = Loadable({
  loader: () => import('../../../src/pages/dashboard/Dashboard')
});

const TermsOfUse = Loadable({
  loader: () => import('../../../src/pages/common/TermsOfUse')
});

const PrivacyPolicy = Loadable({
  loader: () => import('../../../src/pages/common/PrivacyPolicy')
});
export const publicRoutes = [
  {
    path: '/',
    component: (
      // <DefaultLayout>
      <Dashboard />
      // </DefaultLayout>
    ),
    module: 'generic'
  },
  {
    path: '/Login',
    component: <Dashboard />,
    module: 'generic'
  },
  {
    path: '/reset-password',
    subPath: [':secretCode'],
    component: <Dashboard />,
    module: 'generic'
  },
  {
    path: '/Login/:type/:data',
    component: <Dashboard />,
    subPath: ['', ':utm'],
    module: 'generic'
  },
  // {
  //   path: '/registration',
  //   component: <Registration />,
  //   subPath: ['', ':email', ':email/:referal_code'],
  //   module: 'generic'
  // },
  {
    path: '/forget-password',
    component: <Dashboard />,
    subPath: [''],
    module: 'generic'
  },
  {
    path: '/terms-of-use',
    component: <TermsOfUse />,
    subPath: [''],
    module: 'generic'
  },
  {
    path: '/privacy-policy',
    component: <PrivacyPolicy />,
    subPath: [''],
    module: 'generic'
  }
];
