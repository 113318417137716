import React, { memo } from 'react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';

// ReactGA.initialize('G-MMRQERMH4E');
ReactGA.initialize(process.env.REACT_APP_GA_ID);

function RootLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default memo(RootLayout);
