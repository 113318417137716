import { generateUtmExt, log } from '../../common/common';
import { certificateConstants } from '../constants/certificate.constant';
import axiosInstance from '../helper/axiosInstance';
import { logoutUser } from './login.action';

export const getCertificates = (pagination, to, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`certificate/listing?from=${pagination}&to=${to}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: certificateConstants.GET_CERT_LIST_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
        dispatch({
          type: certificateConstants.GET_CERT_LIST_FALIURE,
          payload: error
        });
      });
  };
};
export const getCertificateData = (id, callback) => {
  return (dispatch) => {
    axiosInstance
      .get(`certificate/detail?type_id=${id}${generateUtmExt()}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: certificateConstants.GET_CERT_DETAILS_SUCCESS,
            payload: response.data
          });
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
        dispatch({
          type: certificateConstants.GET_CERT_DETAILS_FALIURE,
          payload: error
        });
      });
  };
};
export const addCertificate = (jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('certificate/add', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
export const editCertificate = (id, jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('certificate/update', { ...jsonObj, id })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
export const deleteCertificate = (id, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('certificate/delete', { id })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
export const getCertCounts = (callback) => {
  return (dispatch) => {
    axiosInstance
      .get('certificate/count')
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
export const reportCertificateChange = (jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('knwlgmastersessionnew/editCertificate', jsonObj)
      .then((response) => {
        // if (response.data) {
        callback(response.data);
        // }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv19/certificate/listing?from=0&to=10
//https://developer.clirnet.com/knowledge/rnv19/certificate/detail?type_id=1
//https://developer.clirnet.com/knowledge/rnv23/Knwlgmastersessionnew/editCertificate
// {"first_name":"Indranilsssssw","middle_name":"","last_name":"Kundu","certificate_id":"3","session_id":"6062","type":"namechange"}
//{"other_text":"asasasasasasa","certificate_id":"5","session_id":"6062","type":"other"}
export const generateTrainingCertificate = (training_id, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('training/certificate', {
        type: 'generate',
        training_id
      })
      .then((response) => {
        if (response.data.status_code == 200) {
          callback && callback(response.data?.data);
        } else if (response.data.status_code == 203) {
          callback && callback(null);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback(null);
      });
  };
};
export const renameTrainingCertificate = (jsonObj, callback) => {
  return (dispatch) => {
    axiosInstance
      .post('training/certificate', jsonObj)
      .then((response) => {
        if (response.data) {
          callback && callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('Error', error);
        callback('error');
      });
  };
};
//training/certificate
