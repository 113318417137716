import { routeNames } from '../../router/constants';

const importStatements = [
  {
    path: routeNames.speciality.landing,
    statement: () => import('../../pages/speciality/SpecialityLanding')
  },
  {
    path: routeNames.speciality.vault,
    statement: () => import('../../pages/vault/Vault')
  },
  {
    path: routeNames.helpAndSupport.landing,
    statement: () => import('../../pages/helpAndSupport/HelpAndSupport')
  },
  {
    path: routeNames.search.landing,
    statement: () => import('../../pages/Search/SearchV3')
  },
  {
    path: routeNames.search.tag,
    statement: () => import('../../pages/Search/TagSearch')
  },
  {
    path: routeNames.search.listing,
    statement: () => import('../../pages/Search/SearchList')
  },
  {
    path: routeNames.certificate.upload,
    statement: () => import('../../pages/certificates/UploadCertificate')
  },
  {
    path: routeNames.certificate.landing,
    statement: () => import('../../pages/certificates/CertificatesListing')
  },
  {
    path: routeNames.cpdReview.landing,
    statement: () => import('../../pages/userProfile/CpdReview')
  },
  {
    path: routeNames.sessions.upcoming,
    statement: () => import('../../pages/SpeakerProfile/UpcomingSessionsList')
  },
  {
    path: routeNames.sessions.previous,
    statement: () => import('../../pages/SpeakerProfile/PrviousSession')
  },
  {
    path: routeNames.sessions.queryListing,
    statement: () => import('../../pages/SpeakerProfile/QueryListing')
  },
  {
    path: routeNames.spq.question,
    statement: () => import('../../pages/spq/SPQquestion')
  },
  {
    path: routeNames.spq.review,
    statement: () => import('../../pages/spq/SPQreview')
  },
  {
    path: routeNames.spq.result,
    statement: () => import('../../pages/spq/SPQsubmitSuccessfully')
  },
  {
    path: routeNames.spq.attempt,
    statement: () => import('../../pages/spq/SPQcompleted')
  },
  {
    path: routeNames.spq.listing,
    statement: () => import('../../pages/spq/spq')
  },
  {
    path: routeNames.spq.listingPoll,
    statement: () => import('../../pages/spq/spq')
  },
  {
    path: routeNames.sessions.landing,
    statement: () => import('../../pages/session/SessionListing')
  },
  {
    path: routeNames.sessions.listing,
    statement: () => import('../../pages/session/AllSessionList')
  },
  {
    path: routeNames.epaper.landing,
    statement: () => import('../../pages/ebook/EbookListing')
  },
  {
    path: routeNames.medwiki.listing,
    statement: () => import('../../pages/medwiki/MedwikiListing')
  },
  {
    path: routeNames.medwiki.mostCommented,
    statement: () => import('../../pages/medwikiV2/MostCommentedMedwikiListing')
  },
  {
    path: routeNames.medwiki.popular,
    statement: () => import('../../pages/medwiki/MedwikiListing')
  },

  {
    path: routeNames.clinicalVideo.listing,
    statement: () => import('../../pages/clinicalVideo/ClinicalVideoList')
  },
  {
    path: routeNames.medwiki.landing,
    statement: () => import('../../pages/medwikiV2/MedwikiLandingV2')
  },
  {
    path: routeNames.clinicalVideo.landing,
    statement: () => import('../../pages/clinicalVideo/ClinicalVideoLanding')
  },
  {
    path: routeNames.medwiki.topRated,
    statement: () => import('../../pages/medwikiV2/TopRatedMedwikiListing')
  },
  {
    path: routeNames.spq.landing,
    statement: () => import('../../pages/spq/SPQlanding')
  },
  {
    path: routeNames.spq.points,
    statement: () => import('../../pages/spq/UserPoint')
  },
  {
    path: routeNames.course.landing,
    statement: () => import('../../pages/grTraining/TrainingLanding')
  },

  {
    path: routeNames.course.summary,
    statement: () => import('../../pages/grTraining/TrainingSummary')
  },
  {
    path: routeNames.notification.landing,
    statement: () => import('../../pages/notification/Notification')
  },
  {
    path: routeNames.course.listing,
    statement: () => import('../../pages/grTraining/TrainingListing')
  },
  {
    path: routeNames.dashboard.landing,
    // statement: () => import('../../pages/uiv3/dashboard/Dashboard'),
    oldStatement: () => import('../../pages/dashboard/Dashboard')
  },
  {
    path: routeNames.userProfile.landing,
    // statement: () => import('../../pages/uiv3/profile/Profile'),
    oldStatement: () => import('../../pages/userProfile/UserProfileBkp')
  },
  {
    path: routeNames.speciality.listing
    // statement: () => import('../../pages/uiv3/AllSpeciality')
  },
  {
    path: routeNames.course.content,
    statement: () => import('../../pages/grTraining/TrainingContent')
  },
  {
    path: routeNames.sessions.speaker,
    statement: () => import('../../pages/SpeakerProfile/SpeakerProfile')
  },
  {
    path: routeNames.certificate.detail,
    statement: () => import('../../pages/certificates/CertificateDetails')
  },
  {
    path: routeNames.spq.detail,
    statement: () => import('../../pages/spq/SPQdetails')
  },
  {
    path: routeNames.spq.poll,
    statement: () => import('../../pages/spq/PollDetails')
  },
  {
    path: routeNames.sessions.detail,
    statement: () => import('../../pages/sessionOutlet/SessionDetailsOutlet')
  },
  {
    path: routeNames.clinicalVideo.detail,
    // statement: () => import('../../pages/uiv3/clinicalVideo/ClinicalVideoDetails'),
    oldStatement: () => import('../../pages/clinicalVideo/ClinicalDetails')
  },
  {
    path: routeNames.sessions.live,
    statement: () => import('../../pages/sessionOutlet/LiveSessionOutlet')
  },
  {
    path: routeNames.sessions.photoBooth,
    statement: () => import('../../pages/multidaySession/PhotoBooth')
  },

  {
    path: routeNames.course.cover,
    statement: () => import('../../pages/grTraining/TrainingCover')
  },
  {
    path: routeNames?.share?.training,
    statement: () => import('../../pages/share/TrainingShare'),
    oldStatement: () => import('../../pages/share/TrainingShare')
  },
  {
    path: routeNames?.share?.medwiki,
    statement: () => import('../../pages/share/MedwikiShare'),
    oldStatement: () => import('../../pages/share/MedwikiShare')
  },
  {
    path: '/share/feed/:id',
    statement: () => import('../../pages/share/MedwikiShare'),
    oldStatement: () => import('../../pages/share/MedwikiShare')
  },
  {
    path: routeNames?.share?.poll,
    statement: () => import('../../pages/share/PollShare'),
    oldStatement: () => import('../../pages/share/PollShare')
  },
  {
    path: routeNames?.share?.quiz,
    oldStatement: () => import('../../pages/share/QuizShare'),
    statement: () => import('../../pages/share/QuizShare')
  },
  {
    path: routeNames?.share?.session,
    statement: () => import('../../pages/share/SessionShare'),
    oldStatement: () => import('../../pages/share/SessionShare')
  },
  {
    path: routeNames?.share?.doctorVoice,
    statement: () => import('../../pages/share/PollShare'),
    oldStatement: () => import('../../pages/share/PollShare')
  },
  {
    path: routeNames?.share?.epub,
    statement: () => import('../../pages/share/ebookShare'),
    oldStatement: () => import('../../pages/share/ebookShare')
  },
  {
    path: routeNames?.share?.profile,
    statement: () => import('../../pages/share/profileShare'),
    oldStatement: () => import('../../pages/share/profileShare')
  },
  {
    path: routeNames?.share?.clinicalVideo,
    statement: () => import('../../pages/share/ClinicalVideosShare'),
    oldStatement: () => import('../../pages/share/ClinicalVideosShare')
  }
];
export default importStatements;
