import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';

const OldLayoutManager = ({ children }) => {
  useEffect(() => {
    document.body.setAttribute('data-bs-theme', 'All');
  }, []);
  return (
    <div className="w-100 d-flex flex-column position-relative speciality-portal--defaultLayout">
      <div className="container-fluid">
        <Row className="align-items-start flex-nowrap">{children}</Row>
      </div>
    </div>
  );
};

export default OldLayoutManager;
